
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    link: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => ''
    }
  }
})
