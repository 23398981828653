
import { defineComponent, onMounted } from 'vue'

import AsPreloader from '@web/components/AsPreloader.vue'
import AsTechQuestion from '@web/components/AsTechQuestion.vue'
import AsRenews from '@web/components/AsRenews.vue'
import useRenewModal from '@web/composition/useRenewModal'
import AcList from '@web/components/AcList.vue'
import { useRouter, useRoute } from 'vue-router'
import { EXTEND_MODEL } from '@web/consts/CourseExtentends'

export default defineComponent({
  components: {
    AsPreloader,
    AsTechQuestion,
    AsRenews,
    AcList
  },
  props: {
    streamId: {
      type: Number,
      require: true
    },
    type: {
      type: String,
      default: () => ''
    },
    isShowPromocode: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['close'],
  setup (props, ctx) {
    const router = useRouter()
    const route = useRoute()

    const {
      init,
      onSubmitTechQuestion,
      ...data
    } = useRenewModal({
      router,
      route,
      streamId: Number(props.streamId),
      type: props.type
    })

    function onSendTechQuestion () {
      onSubmitTechQuestion()
      ctx.emit('close')
    }

    onMounted(init)

    return {
      ...data,
      EXTEND_MODEL,
      onSendTechQuestion
    }
  }
})
