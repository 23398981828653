import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21a3e666"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "asRenews" }
const _hoisted_2 = ["onClick", "data-analytics-element-name"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_input = _resolveComponent("as-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "service",
        onClick: ($event: any) => (_ctx.setSelectedService(service)),
        key: `service-${service.id}`,
        "data-analytics": "",
        "data-analytics-element-type": "other",
        "data-analytics-element-id": "renew",
        "data-analytics-element-name": `service-${service.id}`
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["service_checkbox", { '-checked': _ctx.isChecked(service) }])
        }, null, 2),
        _createTextVNode(" " + _toDisplayString(service.title) + " " + _toDisplayString(service.priceText), 1)
      ], 8, _hoisted_2))
    }), 128)),
    (_ctx.isShowPromocode)
      ? (_openBlock(), _createBlock(_component_as_input, {
          key: 0,
          class: "asRenews_input -grey",
          label: _ctx.$t('Discount'),
          placeholder: _ctx.$t('Enter discount'),
          type: "text",
          modelValue: _ctx.discount,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.discount) = $event)),
          onChange: _ctx.handlerChangeDiscount,
          error: _ctx.discountError
        }, null, 8, ["label", "placeholder", "modelValue", "onChange", "error"]))
      : _createCommentVNode("", true),
    _createElementVNode("a", {
      class: _normalizeClass(["asRenews_button", { '-disabled': _ctx.isRenewBtnDisabled }]),
      href: _ctx.renewUrl,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
    }, _toDisplayString(_ctx.textBtnWithPrice), 11, _hoisted_3)
  ]))
}