
import { defineComponent, ref, computed, watch } from 'vue'

import AsInput from '@web/components/AsInput/AsInput.vue'

import getBaseUrl from '@web/common/getBaseUrl'
import Token from '@web/common/token'

import i18n from '@web/plugins/i18n'
import { IS_MOBILE } from '@web/common/config'
import { Service } from '@web/store/types/modules/service'

import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import locale from '@web/common/locale'

export default defineComponent({
  components: {
    AsInput
  },
  props: {
    streamId: {
      type: Number,
      require: true
    },
    type: {
      type: String,
      default: () => ''
    },
    isShowPromocode: {
      type: Boolean,
      default: () => false
    },
    services: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()

    const selectedService = ref<Service | null>(null)
    const discount = ref('')
    const discountError = ref('')

    const textBtnWithPrice = computed(() => {
      if (selectedService.value) {
        return `${i18n.global.t('Buy for')} ${selectedService.value.priceText}`
      } else {
        return i18n.global.t('Buy')
      }
    })
    const isRenewBtnDisabled = computed(() => !selectedService.value)
    const renewUrl = computed(() => {
      if (!selectedService.value) {
        return ''
      }
      return `${getBaseUrl()}/${locale.get()}/extend/course/${props.streamId}/tariff/${selectedService.value.id}?token=${Token.get()}&utm_source=lk&utm_medium=prolongation_button`
    })

    function setSelectedService (val) {
      selectedService.value = val
    }
    async function handlerChangeDiscount () {
      if (selectedService.value !== null && discount.value.length > 0) {
        const { success, message } = await store.dispatch('extend/checkDiscount', { serviceId: selectedService.value.id, discount: discount.value })
        discountError.value = success ? '' : (message || '')
      } else {
        discountError.value = ''
      }
    }
    function isChecked ({ id }) {
      return selectedService.value ? id === selectedService.value.id : false
    }
    function open (e) {
      if (IS_MOBILE) {
        e.preventDefault()
        e.stopPropagation()

        router.push({
          name: 'iframe',
          query: { url: e.target.href }
        })
      }
    }

    watch(() => props.services, () => {
      if (!selectedService.value && props.services.length > 0) {
        setSelectedService(props.services[0])
      }
    }, {
      immediate: true
    })

    return {
      textBtnWithPrice,
      discount,
      discountError,
      isRenewBtnDisabled,
      renewUrl,

      setSelectedService,
      isChecked,
      handlerChangeDiscount,
      open
    }
  }
})
