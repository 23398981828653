export const EXTEND_MODEL = {
  STANDARD_EXTEND: 'standard_extend',
  NEW_BUY_EXTEND: 'new_buy_extend',
  DISABLED_EXTEND: 'disable_extend',
  SUPPORT_EXTEND: 'support_extend'
}

export enum ExtendModelType {
  STANDARD_EXTEND = 'standard_extend',
  NEW_BUY_EXTEND = 'new_buy_extend',
  DISABLED_EXTEND = 'disable_extend',
  SUPPORT_EXTEND = 'support_extend'
}
