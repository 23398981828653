import Mapper from '@web/mapper/Mapper'
import { Service } from '@web/store/types/modules/service'
import ServiceMapper from '@web/mapper/ServiceMapper'

interface PaymentSystemAppStore {
  quantity: string;
  productId: string;
  purchaseDate: string;
  transactionId: string;
  isTrialPeriod: string;
  purchaseDateMs: string;
  purchaseDatePst: string;
  originalPurchaseDate: string;
  originalTransactionId: string;
  originalPurchaseDateMs: string;
  originalPurchaseDatePst: string;
}

interface Invoice {
  id: number;
  orderId: number;
  amount: number;
  status: string;
  paymentSystemId: number;
  createdBy: number;
  updatedBy: number;
  createdAt: number;
  updatedAt: number;
  isReceiptSent: boolean;
  currency: string;
  paymentSystemData: {
    appStore?: PaymentSystemAppStore;
  };
}

interface AppStoreM {
  new: number[];
  items: {
    invoice: Invoice;
    service: Service;
  }[];
  success: boolean;
  errors: {
    appstoreProductId: string;
    message: string;
  }[];
}

export default class AppStoreMapper extends Mapper {
  static toClient (data: unknown): AppStoreM {
    return Mapper.converter({
      data,
      rules: {
        items: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          cb: items => Object.values(items).map((item: any) => {
            return {
              service: ServiceMapper.toClient(item.courseService).service,
              invoice: Mapper.converter({
                data: item.invoice,
                rules: {
                  paymentSystemData: {
                    cb: paymentSystemDataItem => Mapper.converter({
                      data: paymentSystemDataItem,
                      rules: {
                        appStore: {
                          cb: Mapper.toClient
                        }
                      }
                    })
                  }
                }
              })
            }
          })
        }
      }
    }) as AppStoreM
  }
}
