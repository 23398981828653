import client from '../client'
import HeaderMapper from '@web/mapper/HeaderMapper'
import AppStoreMapper from '@web/mapper/AppStoreMapper'

export default class AppStore {
  static buy (receipt: string) {
    return client.post('/payment/app-store/buy', {
      receiptBase64Data: receipt
    })
      .then(response => {
        return {
          data: AppStoreMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static validate (paymentSystemId: number, appId: string) {
    return client.get('/payment/app-store/validate-course-service', {
      params: {
        paymentSystemId,
        appId
      }
    })
      .then(response => {
        return {
          data: response.data,
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
