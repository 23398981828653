import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "acList" }
const _hoisted_2 = { class: "acList_loading" }
const _hoisted_3 = {
  key: 1,
  class: "acList_list"
}
const _hoisted_4 = { class: "acList_preview" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "acList_content" }
const _hoisted_7 = { class: "acList_author" }
const _hoisted_8 = { class: "acList_title" }
const _hoisted_9 = { class: "acList_description" }
const _hoisted_10 = { class: "asList_note" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_as_button = _resolveComponent("as-button")!
  const _component_as_empty = _resolveComponent("as-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_as_preloader, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('productServeLoading')), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (p, id) => {
            return (_openBlock(), _createElementBlock("li", {
              key: id,
              class: "acList_course"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: _ctx.getProduct(p).preview,
                  class: "acList_pic"
                }, null, 8, _hoisted_5)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getProduct(p).author), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getProduct(p).title), 1),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.getProduct(p).description), 1),
                _createElementVNode("span", _hoisted_10, [
                  (_ctx.getProduct(p).state === 'initiated')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('paymentInProgress')), 1)
                      ], 64))
                    : _createCommentVNode("", true),
                  (_ctx.isProductLoading(_ctx.getProduct(p).state))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('productInfoLoading')), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.getProduct(p).price === 0)
                  ? (_openBlock(), _createBlock(_component_as_button, {
                      key: 0,
                      class: "acList_button",
                      "data-cy": `buttonFree_${_ctx.getProduct(p).serviceId}`,
                      onClick: ($event: any) => (_ctx.createOrder(_ctx.getProduct(p).serviceId, _ctx.getProduct(p).appstoreProductId, _ctx.getProduct(p).price))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('tryFree')), 1)
                      ]),
                      _: 2
                    }, 1032, ["data-cy", "onClick"]))
                  : (_ctx.getProduct(p).canPurchase)
                    ? (_openBlock(), _createBlock(_component_as_button, {
                        key: 1,
                        class: "acList_button",
                        "data-cy": `buttonBuy_${_ctx.getProduct(p).serviceId}`,
                        onClick: ($event: any) => (_ctx.createOrder(_ctx.getProduct(p)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Buy for')) + " " + _toDisplayString(_ctx.getProduct(p).priceText), 1)
                        ]),
                        _: 2
                      }, 1032, ["data-cy", "onClick"]))
                    : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ])),
    (_ctx.products.length === 0)
      ? (_openBlock(), _createBlock(_component_as_empty, { key: 2 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('emptyCatalog')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}