
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsButton from '@web/components/AsButton.vue'
import Logger from '@web/common/Logger'
import Swal from 'sweetalert2'
import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import { isDefined } from 'ts-is-present'
import useAppStore, { appStore } from '@web/composition/useAppStore'
import Order from '@web/api/modules/Order'
import * as AnalyticEvents from '@web/services/Analytics/events'
import AsEmpty from '@web/components/AsEmpty.vue'
import { Service } from '@web/store/types/modules/service'
import { IS_IOS } from '@web/common/config'
import { isAppStoreSupport } from '@web/common/ExternalConfig/getters'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    serviceIds: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const isLoading = ref(false)
    const store = useStore()
    const isRequestVerify = ref(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let registerProduct = (productId: string) => Logger.info('Function registerProduct not implemented')

    function onBuy (status, event?) {
      // TODO: в интерфейсе надо пользователю показывать что сейчас происходит покупка
      if (status === 'start') {
        isLoading.value = true
      }
      if (status === 'error') {
        Logger.error(event)
      }
      if (status === 'finish') {
        isLoading.value = false
      }
    }

    const products = computed(() => {
      return props.serviceIds.map(serviceId => {
        const service = store.getters['service/getServiceById'](Number(serviceId))

        if (service && service.typeCode === 'course_demo' && store.getters['course/getCourseById'](Number(service?.courseId))) {
          return undefined
        }

        const course = store.getters['course/getCourseById'](Number(service?.courseId))
        const appstoreProductId = service?.appstoreProductId || ''
        if (IS_IOS && isAppStoreSupport() && appstoreProductId) {
          registerProduct(appstoreProductId)
        }

        if (service && course) {
          return {
            service: service,
            course: course,
            appstoreProductId: service.appstoreProductId
          }
        }
        return undefined
      }).filter(isDefined)
    })

    function getProduct ({ service, course }: { service: Service; course: Course }) {
      let canPurchase = service ? service.price > 0 : false
      let priceText = service.priceText
      let state = ''
      const appstoreProductId = service.appstoreProductId

      if (IS_IOS && isAppStoreSupport()) {
        const defaultAppstoreProduct = {
          price: '',
          canPurchase: false,
          state: ''
        }
        const appstoreProduct = store.getters['appStore/getProductById'](appstoreProductId) || defaultAppstoreProduct

        priceText = appstoreProduct.price
        canPurchase = appstoreProduct.canPurchase
        state = appstoreProduct.state

        if (service && service.price > 0) {
          canPurchase = appstoreProduct.canPurchase
        }
      }

      return {
        service,
        course,
        serviceId: service.id,
        preview: course.image.small,
        title: course.caption,
        description: i18n.global.t(`serviceType.${service.typeCode}`),
        author: course.coachName,
        price: service.price,
        appstoreProductId: service.appstoreProductId,
        state,
        priceText,
        canPurchase
      }
    }

    function successPurchasedCourseAlert () {
      Swal.fire({
        title: i18n.global.t('successPurchasedCourse'),
        text: i18n.global.t('askAfterSuccessPurchase'),
        showCancelButton: true,
        confirmButtonText: i18n.global.t('yes'),
        cancelButtonText: i18n.global.t('no'),
        icon: 'success'
      }).then(result => {
        if (result.value) {
          if (router.currentRoute.value.name !== 'courses') {
            router.push({
              name: 'courses'
            })
          } else {
            window.location.reload()
          }
        }
        store.dispatch('stream/fetchUserCourseStreams')
      })
    }
    let purchase = (product: { productId: string; serviceId: number }) => Logger.warn('Function registerProduct not implemented', product)
    let destroy = () => Logger.info('Function destroy not implemented')
    if (IS_IOS && isAppStoreSupport()) {
      const data = useAppStore({ successPurchasedCourseAlert, isRequestVerify, onBuy })
      registerProduct = data.registerProduct
      destroy = data.destroy
      purchase = data.purchase
    }
    onBeforeUnmount(destroy)

    function createOrder ({ serviceId, appstoreProductId, price }) {
      if (IS_IOS && price > 0 && isAppStoreSupport() && appstoreProductId) {
        isRequestVerify.value = true
        purchase({ serviceId, productId: appstoreProductId })
      } else {
        Order.create(serviceId)
          .then(response => {
            if (response.order.status === 'paid') {
              const service = store.getters['service/getServiceById'](Number(serviceId))
              if (service && service.typeCode === 'course_demo') {
                AnalyticEvents.addDemoCourse({ serviceId, courseId: Number(service.courseId) })
              }
              successPurchasedCourseAlert()
            } else {
              const url = response.order.payout_url
              window.open(url, '_system')
            }
          })
      }
    }

    const refresh = () => {
      appStore().refresh()
    }

    function isProductLoading (state) {
      return IS_IOS ? state === appStore().REGISTERED : false
    }

    return {
      isLoading,
      products,
      createOrder,
      refresh,
      getProduct,
      isProductLoading
    }
  },
  components: {
    AsPreloader,
    AsButton,
    AsEmpty
  }
})
