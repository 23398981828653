import Swal from 'sweetalert2'
import AppStoreAPI from '@web/api/modules/AppStore'
import Logger from '@web/common/Logger'
import { useStore } from '@web/store'
import sentry from '@web/common/sentry'
import { IS_IOS } from '@web/common/config'
import * as AnalyticEvents from '@web/services/Analytics/events'
import IStoreProduct = IapStore.IStoreProduct
import i18n from '@web/plugins/i18n'

export const appStore = () => {
  window.store.verbosity = window.store.INFO
  // window.store.validator = process.env.VUE_APP_PURCACHE_VALIDATE_URL
  return window.store
}

const render = (product: IStoreProduct) => {
  const store = useStore()
  if (product) {
    store.commit('appStore/receive', product)
  }
}

export function registerUpdateProduct () {
  if (IS_IOS) {
    appStore().when('product').updated(render)
  }
}

export default function useAppStore ({
  successPurchasedCourseAlert,
  isRequestVerify,
  onBuy
}) {
  const approved = product => {
    product.verify()
  }

  const verified = product => {
    if (product.type === 'application') {
      product.finish()
      return
    }

    if (typeof product.transaction.appStoreReceipt === 'string') {
      onBuy('start')
      AppStoreAPI.buy(product.transaction.appStoreReceipt)
        .then(({ data }) => {
          if (data.success) {
            product.finish()
          }

          if (data.new.length > 0) {
            onBuy('success')
            successPurchasedCourseAlert()

            data.new.forEach(invoiceId => {
              const item = data.items.find(el => el.invoice.id === invoiceId)
              if (item) {
                AnalyticEvents.inAppPurchase().purchase({
                  serviceName: item.service.title,
                  price: item.invoice.amount,
                  orderId: item.invoice.orderId,
                  quantity: 1,
                  type: item.service.typeCode,
                  serviceId: item.service.id
                })
              }
            })
          } else if (data.errors.length > 0) {
            const error: {
              appstoreProductId: string;
              message: string;
            } | undefined = data.errors.find(e => e.appstoreProductId === product.id)
            if (error && isRequestVerify.value) {
              isRequestVerify.value = false
              throw new Error(error.message)
            }
          }
          isRequestVerify.value = false
        })
        .catch(e => {
          onBuy('error', e)

          let text = i18n.global.t('error.verifiedPurchase')
          if (e.message) {
            text += ` (${e.message})`
          }
          Swal.fire({
            title: i18n.global.t('error.error'),
            text,
            icon: 'error'
          })

          Logger.error(e)

          sentry.captureException(e)
        })
        .finally(() => {
          onBuy('finish')
        })
    }
  }

  let countErrors = 0
  const error = error => {
    countErrors++
    Logger.error(`Code: ${error.code}. Message: ${error.message}`)

    if (countErrors >= 5) {
      Swal.fire({
        title: i18n.global.t('error.aLotOfErrors'),
        text: error.message,
        icon: 'error',
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: i18n.global.t('reloadApp'),
        denyButtonText: i18n.global.t('Cancel')
      })
        .then(response => {
          if (response.isConfirmed) {
            window.location.reload()
          } else {
            appStore().update()
          }
        })
    } else {
      Swal.fire({
        title: i18n.global.t('error.error'),
        text: error.message,
        icon: 'error'
      })

      appStore().refresh()
    }
  }

  const registerProduct = (productId: string, alias?: string) => {
    if (!appStore().get(productId)) {
      appStore().register({
        id: productId,
        alias: alias || '',
        type: appStore().NON_RENEWING_SUBSCRIPTION
      } as IapStore.IRegisterRequest)

      appStore().when(productId).loaded(render)
      appStore().refresh()
    }

    render(appStore().get(productId))
  }

  appStore().when('product').approved(approved)
  appStore().when('product').verified(verified)

  const purchase = (payload: { productId: string; serviceId: number }) => {
    appStore().order(payload.productId)

    const store = useStore()
    const service = store.getters['service/getServiceById'](payload.serviceId)
    AnalyticEvents.inAppPurchase().beginCheckout({
      serviceId: payload.serviceId,
      serviceName: service?.title || String(payload.serviceId),
      price: service?.price || 0,
      type: service?.typeCode || 'unknown',
      quantity: 1
    })
  }

  const refresh = () => {
    appStore().refresh()
  }
  appStore().error(error)

  appStore().validator = (product, callback) => {
    // eslint-disable-next-line node/no-callback-literal
    callback(true, {})
  }

  function destroy () {
    if (IS_IOS) {
      appStore().off(approved)
      appStore().off(verified)
      appStore().off(error)
    }
  }

  return {
    purchase,
    refresh,
    registerProduct,
    destroy
  }
}
