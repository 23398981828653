import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bd5935a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "asRenewModal",
  "data-cy": "renewModal"
}
const _hoisted_2 = { class: "asRenewModal_title" }
const _hoisted_3 = {
  key: 0,
  class: "newExtend"
}
const _hoisted_4 = { class: "newExtend_wrapper" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 2,
  class: "asRenewModal_content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_as_tech_question = _resolveComponent("as-tech-question")!
  const _component_ac_list = _resolveComponent("ac-list")!
  const _component_as_renews = _resolveComponent("as-renews")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isShowPreloader)
      ? (_openBlock(), _createBlock(_component_as_preloader, {
          key: 0,
          class: "asRenewModal_preloader"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.showTechQuestion)
            ? (_openBlock(), _createBlock(_component_as_tech_question, {
                key: 0,
                "stream-id": _ctx.streamId,
                "category-id": _ctx.renewSupportCategoryId,
                class: "asRenewModal_techQuestion",
                onSubmit: _ctx.onSendTechQuestion,
                msg: _ctx.messageTechSupport
              }, null, 8, ["stream-id", "category-id", "onSubmit", "msg"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
                (_ctx.extendModel === _ctx.EXTEND_MODEL.NEW_BUY_EXTEND)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('newExtend.dearUsers')), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('newExtend.notExtendText')), 1)
                      ]),
                      _createElementVNode("a", {
                        class: "newExtend_button",
                        href: _ctx.extendPageUrl,
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
                      }, _toDisplayString(_ctx.$t('More')), 9, _hoisted_5)
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.isShowList)
                        ? (_openBlock(), _createBlock(_component_ac_list, {
                            key: 0,
                            "service-ids": [_ctx.serviceId]
                          }, null, 8, ["service-ids"]))
                        : _createCommentVNode("", true),
                      (_ctx.isShowRenew)
                        ? (_openBlock(), _createBlock(_component_as_renews, {
                            key: 1,
                            type: _ctx.type,
                            "stream-id": _ctx.streamId,
                            services: _ctx.services,
                            "is-show-promocode": _ctx.isShowPromocode
                          }, null, 8, ["type", "stream-id", "services", "is-show-promocode"]))
                        : _createCommentVNode("", true),
                      (_ctx.isEmpty)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createTextVNode(_toDisplayString(_ctx.placeholder) + " ", 1),
                            _createElementVNode("button", {
                              class: "asRenewModal_button",
                              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToSupport && _ctx.goToSupport(...args)))
                            }, _toDisplayString(_ctx.$t('questionTechSupport')), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
              ], 64))
        ], 64))
  ]))
}