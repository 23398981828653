import { useStore } from '@web/store'
import { RouteLocation, Router, RouteLocationNormalized } from 'vue-router'
import i18n from '@web/plugins/i18n'
import sentry from '@web/common/sentry'
import { computed, ref } from 'vue'
import Logger from '@web/common/Logger'
import { IS_IOS, IS_MOBILE } from '@web/common/config'
import {
  renewConsultationSupportCategoryId,
  renewCourseSupportCategoryId
} from '@web/common/ExternalConfig/getters'
import Analytics from '@web/services/Analytics/Analytics'
import { EXTEND_MODEL } from '@web/consts/CourseExtentends'

type options = {
  router: Router;
  route: RouteLocationNormalized;
  streamId: number;
  type: string;
}

export default function ({ router, route, streamId, type }: options) {
  const store = useStore()
  const isEmpty = ref(true)
  const loading = ref(false)
  const serviceId = ref<number>(0)

  const stream = computed(() => streamId ? store.getters['stream/getStreamById'](streamId) : undefined)
  const course = computed(() => stream.value?.courseId ? store.getters['course/getCourseById'](stream.value?.courseId) : undefined)
  const extendModel = computed(() => course.value?.extendModel || EXTEND_MODEL.STANDARD_EXTEND)
  const extendPageUrl = computed(() => course.value?.extendPageUrl || '')

  const title = computed(() => {
    switch (type) {
      case 'course':
        return course.value?.extendModel === EXTEND_MODEL.NEW_BUY_EXTEND ? `${i18n.global.t('renewTitles.course')} ${course.value?.caption}` : `${i18n.global.t('renewTitles.course')} ${course.value?.caption}`
      case 'consultation':
        return i18n.global.t('renewTitles.consultation')
      default:
        return i18n.global.t('renewTitles.default')
    }
  })
  const isShowPreloader = computed(() => loading.value)
  const services = computed(() => {
    switch (type) {
      case 'course':
        return streamId ? store.getters['extend/getExtendsCourseByStreamId'](streamId) : []
      case 'consultation':
        return streamId ? store.getters['extend/getExtendsConsultationByStreamId'](streamId) : []
      default:
        return streamId ? store.getters['extend/getExtendsByStreamId'](streamId) : []
    }
  })
  const placeholder = computed(() => {
    switch (type) {
      case 'course':
        return i18n.global.t('requestSupportFromBuy.course')
      case 'consultation':
        return i18n.global.t('requestSupportFromBuy.consultation')
      default:
        return i18n.global.t('requestSupportFromBuy.default')
    }
  })
  const renewSupportCategoryId = computed<number | undefined>(() => {
    switch (type) {
      case 'course':
        return renewCourseSupportCategoryId()
      case 'consultation':
        return renewConsultationSupportCategoryId()
      default:
        return undefined
    }
  })
  const category = computed(() => renewSupportCategoryId.value ? store.getters['faq/getCategoryById'](renewSupportCategoryId.value) : undefined)
  const showTechQuestion = computed(() => {
    return Boolean(isEmpty.value && streamId && renewSupportCategoryId.value) || course.value?.extendModel === EXTEND_MODEL.SUPPORT_EXTEND
  })
  const messageTechSupport = computed<string>(() => {
    switch (type) {
      case 'course':
        return i18n.global.t('notRenewExtensions.cantBuyCourse')
      case 'consultation':
        return i18n.global.t('notRenewExtensions.cantBuyConsultation')
      default:
        return ''
    }
  })

  async function init () {
    loading.value = true
    try {
      if (IS_IOS) {
        const stream = await store.dispatch('stream/fetchUserCourseStream', {
          id: streamId,
          expand: 'order'
        })
        isEmpty.value = !stream.orderServiceId
        if (stream.orderServiceId) {
          serviceId.value = stream.orderServiceId
        }
      } else {
        await store.dispatch('extend/fetchExtendsCached', { streamId: streamId })
        isEmpty.value = services.value.length === 0
      }
    } catch (error) {
      sentry.captureException(error)
      Logger.error(error)
    }
    loading.value = false
  }

  function goToSupport () {
    let routeData: RouteLocation = { name: 'support' } as RouteLocation

    if (!IS_MOBILE && streamId && renewSupportCategoryId.value) {
      routeData = {
        name: 'tech-question',
        params: {
          categoryId: String(renewSupportCategoryId.value),
          streamId: String(streamId),
          message: messageTechSupport.value
        }
      } as unknown as RouteLocation
      store.commit('router/setReturnUrl', route.fullPath)
    }

    router.push(routeData)
  }
  function onSubmitTechQuestion () {
    let params: Dictionary<string> = {}
    if (stream.value) {
      const course = store.getters['course/getCourseById'](stream.value.courseId)
      if (course) {
        params = {
          courseTitle: course.title,
          courseId: String(course.id),
          streamId: String(stream.value.streamId),
          userCourseStreamId: String(stream.value.id)
        }
      }
    }
    if (category.value) {
      params = {
        ...params,
        allowAutoResolve: String(category.value.allowAutoResolve),
        sort: String(category.value.sort),
        requireProduct: String(category.value.requireProduct),
        targetProduct: String(category.value.targetProduct),
        categoryId: String(category.value.id),
        categoryName: category.value.name,
        categoryTitle: category.value.title
      }
    }
    Analytics.send({
      category: 'support_activity',
      action: 'support_send_question',

      auto_resolve: 'false',
      ...params
    })
  }

  const isShowRenew = computed<boolean>(() => !IS_IOS && services.value.length > 0)
  const isShowList = computed<boolean>(() => IS_IOS && !!serviceId.value)

  return {
    stream,
    course,
    extendModel,
    extendPageUrl,
    title,
    isShowPreloader,
    services,
    placeholder,
    goToSupport,
    init,
    isEmpty,
    isIOS: IS_IOS,
    isShowRenew,
    isShowList,
    serviceId,
    renewSupportCategoryId,
    onSubmitTechQuestion,
    showTechQuestion,
    messageTechSupport
  }
}
